import * as queryString from "query-string"

import { Error, Layout } from "@components"
import React, { useEffect, useState } from "react"
import { pagePreviewQuery, postPreviewQuery } from "@fragments/preview-queries"

import Page from "@templates/page"
import Post from "@templates/post"
import { Spinner } from "@components"
import styled from "styled-components"

export default function Preview(props) {
  const [previewLoaded, setPreviewLoaded] = useState(false)
  const [previewData, setPreviewData] = useState()
  const [errors, setErrors] = useState()

  const { nonce, post, post_type } = queryString.parse(props?.location?.search)

  function resolveQuery() {
    if (post_type === "page") {
      return pagePreviewQuery
    }
    return postPreviewQuery
  }

  function updateContentType(result, post_type) {
    result.data.post.contentTypeName = post_type
    return result
  }

  useEffect(() => {
    fetch(`${process.env.GATSBY_GRAPHQL_URL}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-WP-Nonce": nonce,
      },
      body: JSON.stringify({
        query: `
		    ${resolveQuery()}
		  `,
        variables: {
          id: post,
        },
      }),
    })
      .then(res => res.json())
      .then(result => {
        setPreviewLoaded(true)
        if (post_type === "page") {
          setPreviewData(result)
          return
        }
        setPreviewData(updateContentType(result, post_type))
      })
      .catch(rejected => {
        setErrors(true)
      })
  }, [])

  return (
    <>
      <Banner>Viewing page in preview mode</Banner>
      {!previewLoaded && (
        <Layout>
          <PreviewWrapper>
            <span>
              <h1>Loading Preview Mode</h1>
              <Spinner />
            </span>
          </PreviewWrapper>
        </Layout>
      )}
      {(previewData?.errors || errors) && (
        <>
          <Layout>
            <Error message="Hmm - that page can't be found" />
          </Layout>
        </>
      )}
      {previewData && !previewData?.errors && previewLoaded && (
        <>
          {post_type === "page" ? (
            <Page data={previewData?.data} />
          ) : (
            <Post data={previewData?.data} />
          )}
        </>
      )}
    </>
  )
}

const PreviewWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(49, 59, 73);
  padding: 6rem 1.5rem;

  @media (min-width: 48rem) {
    padding: 6rem 3rem;
  }

  span {
    display: flex;
    align-items: center;
    flex-direction: column;
    > div {
      margin-top: 0.5rem;
    }
    @media (min-width: 48rem) {
      align-items: flex-end;
      flex-direction: row;
      > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  h1 {
    color: white;
    font-size: 1.5rem;
    @media (min-width: 48rem) {
      font-size: 3rem;
    }
    margin-right: 0.25rem;
    margin-bottom: 0;
  }
`

const Banner = styled.div`
  position: fixed;
  z-index: 5;
  width: 100%;
  padding: 0.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
  color: white;
  font-weight: bold;
`
