export const pagePreviewQuery = `#graphql
query PageById($id: ID!) {
   page(id: $id, idType: DATABASE_ID, asPreview: true) {
      id
      title
      stickyLinks {
        nextLink {
          target
          title
          url
        }
        previousLink {
          target
          title
          url
        }
      }
      pageModules {
        pageModules {
          ... on Page_Pagemodules_PageModules_Hero {
            background
            blockId
            fieldGroupName
            heroPageTitle
            heroTitlePosition
            heroDesktop
            heroMobile
            addHeroImages {
              heroOverlay
              heroImage {
                altText
                sourceUrl
              }
            }
          }

          ... on Page_Pagemodules_PageModules_TextBlock {
            background
            blockId
            fieldGroupName
            paddingBottom
            paddingTop
            textBlockText
          }

          ... on Page_Pagemodules_PageModules_EmployeesGrid {
            background
            blockId
            employees {
              ... on Employee {
                id
                title
                slug
                content
                employeeFields {
                  employeeTitle
                }
                featuredImage {
                  node {
                    altText
                    sourceUrl
                  }
                }
              }
            }
            fieldGroupName
            paddingBottom
            paddingTop
          }

          ... on Page_Pagemodules_PageModules_ProjectsGrid {
            background
            blockId
            fieldGroupName
            paddingBottom
            paddingTop
            projects {
              ... on Project {
                slug
                uri
                title
                categories {
                  nodes {
                    name
                    databaseId
                  }
                }
                featuredImage {
                  node {
                    altText
                    sourceUrl
                  }
                }
              }
            }
          }

          ... on Page_Pagemodules_PageModules_Services {
            background
            blockId
            fieldGroupName
            paddingBottom
            paddingTop
            services {
              ... on Service {
                content
                title
                slug
              }
            }
          }

          ... on Page_Pagemodules_PageModules_NewsArticles {
            background
            blockId
            fieldGroupName
            paddingTop
            paddingBottom
          }

          ... on Page_Pagemodules_PageModules_Vacancies {
            background
            blockId
            fieldGroupName
            paddingTop
            paddingBottom
          }

          ... on Page_Pagemodules_PageModules_CallToAction {
            background
            blockId
            fieldGroupName
            actionLink {
              target
              title
              url
            }
            image {
              altText
              sourceUrl
            }
            subTitle
            textPosition
            title
          }
        }
      }
    }
}
`

export const postPreviewQuery = `#graphql 
   query PostById($id: ID!) {

    post: contentNode(id: $id, idType: DATABASE_ID, asPreview: true) {
      ... on NewsArticle {
        stickyLinks {
          nextLink {
            target
            title
            url
          }
          previousLink {
            target
            title
            url
          }
        }

        seo {
          metaDesc
          title
        }
        title
        content
        contentTypeName
        dateGmt
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        newsFields {
          newsGallery {
            altText
            sourceUrl
          }
        }
      }
      ... on Project {
        stickyLinks {
          nextLink {
            target
            title
            url
          }
          previousLink {
            target
            title
            url
          }
        }
        seo {
          metaDesc
          title
        }
        title
        content
        contentTypeName
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        projectFields {
          projectLocation
          projectDate
          projectGallery {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`
